import { Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { AxiosError, AxiosResponse } from "axios";
import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import InternetExplorerNotAllowed from "../UI/InternetExplorerNotAllowed/InternetExplorerNotAllowed";
import { isNullOrUndefined } from "../utils/CommonUtils";
import { isIE } from "../utils/IsIE";
import {
  initializeAdminFileCard,
  initializeFieldsFromProspectData,
  initializeShipToEmails,
  isBillingEnabledForCompany,
  sendAdminFileCard,
  sendCustomerFileCard,
  setCompanyCodeSpecificDefaultValues,
  storeBrandsFromBackend,
  storeBudgetSplitPerBrandsFromBackend,
  storeCompanyCodeInFileCard,
} from "../utils/MainPageUtils";
import { getQueryStringValue, getTokenFromUrl, isAdminUrl, isEditUrl } from "../utils/QueryStringUtil";
import { ALL_COMPANIES, paymentTermHasDirectDebit, PAYMENT_TERM_CASH_BEFORE_DELIVERY } from "../_constants_/constants";
import { basicDataService } from "../_services_/basicData.service";
import { fileCardService } from "../_services_/fileCard.service";
import { FileCardAction, FileCardFromServer } from "../_types_/actions/FileCardAction";
import { CountryType } from "../_types_/CountryType";
import { TaxJurisdictionType } from "../_types_/serverTypes/TaxJurisdictionType";
import AdminControls from "./AdminControls/AdminControls";
import AdminUser from "./AdminUser/AdminUser";
import BillToAddress from "./BillToAddress/BillToAddress";
import BrandsTable from "./BrandsTable/BrandsTable";
import Budget from "./Budget/Budget";
import Comments from "./Comments/Comments";
import CustomerSaveConfirmation from "./CustomerSaveConfirmation/CustomerSaveConfirmation";
import DataProtectionCheckmarks from "./DataProtectionCheckmarks/DataProtectionCheckmarks";
import DeliveryAddress from "./DeliveryAddress/DeliveryAddress";
import DeliveryOverride from "./DeliveryOverride/DeliveryOverride";
import FileCardExpired from "./FileCardExpired/FileCardExpired";
import Guarantee from "./Guarantee/Guarantee";
import HeaderText from "./HeaderText/HeaderText";
import LogisticContact from "./LogisticContact/LogisticContact";
import NoSingleReturn from "./NoSingleReturn/NoSingleReturn";
import PageNotAvailable from "./PageNotAvailable/PageNotAvailable";
import PaymentTerm from "./PaymentTerm/PaymenTerm";
import PDFPrint from "./PDFPrint/PDFPrint";
import PaymentTermsAccept from "./PaymentTermsAccept/PaymentTermsAccept";
import { FileCardType } from "../_types_/FileCardType";
import {
  addPaymentTermAccepted,
  addShipTo,
  saveFileCardFromBackend,
  setAllCountries,
  setAllTaxJurisdictions,
  setDocumentLanguageCode,
  setFileCardId,
} from "../_reducers_/FileCardSlice";

const MainPage: React.FC = () => {
  const dispatch = useDispatch();

  const [tokenExpired, setTokenExpired] = useState(false);
  const [tokenUnknown, setTokenUnknown] = useState(false);
  const [budgetEnabled, setBudgetEnabled] = useState(false);
  const [budgetVisible, setBudgetVisible] = useState(true);
  const [guaranteeEnabled, setGuaranteeEnabled] = useState(false);
  const [guaranteeTypeEnabled, setGuaranteeTypeEnabled] = useState(false);
  const [guaranteeVisible, setGuaranteeVisible] = useState(true);
  const [commentEnabled, setCommentEnabled] = useState(false);
  const [dataProtectionCheckmark, setDataProtectionCheckmark] = useState(false);
  const [logisticContactVisible, setLogisticContactVisible] = useState(false);
  const [noSingleReturnVisible, setNoSingleReturnVisible] = useState(false);
  const [createAdminUserVisible, setCreateAdminUserVisible] = useState(false);
  const [fileCardSent, setFileCardSent] = useState(false);
  const [adminUserCreationErrorMessage, setAdminUserCreationErrorMessage] = useState<string>();
  const [fileCardAlreadySaved, setfileCardAlreadySaved] = useState(false);
  const [approve, setApprove] = useState(false);
  const [saved, setSaved] = useState(false);
  const [handleStartUpDone, setHandleStartUpDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTaxJurisdictions, setLoadingTaxJurisdictions] = useState(false);
  const [status, setStatus] = useState(-1);
  const [tokenFromUrl, setTokenFromUrl] = useState("");
  const [defaultCountry, setDefaultCountry] = useState<string>("");
  const [prospectEmail, setProspectEmail] = useState("");
  const [additionalAddressInfoEnabled, setAdditionalAddressInfoEnabled] = useState(false);
  const [showAddShipToButton, setShowAddShipToButton] = useState(true);
  const [hideDeliveryOverride, setHideDeliveryOverride] = useState(false);
  const [paymentTermAccept, setPaymentTermAccept] = useState(false);

  const form = createRef<ValidatorForm>();
  const {
    trigger,
    formState,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm();

  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const { t, i18n } = useTranslation("general");

  const shipTos = fileCard.fileCard.shipTos;
  const firstShipToId = "0";

  useEffect(() => {
    basicDataService.getAllCountries().then((response: AxiosResponse<CountryType[]>): void => {
      dispatch(setAllCountries(response.data));
    });
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);
    if (company?.taxJurisdictions) {
      setLoadingTaxJurisdictions(true);
      basicDataService
        .getTaxJurisdictions("USA")
        .then((response: AxiosResponse<TaxJurisdictionType[]>): void => {
          dispatch(setAllTaxJurisdictions(response.data));
        })
        .finally(() => {
          setLoadingTaxJurisdictions(false);
        });
    }
  }, [dispatch, fileCard.fileCard.companyCode]);

  useEffect(() => {
    console.log("errors:", errors);
  }, [errors]);

  useEffect(() => {
    const changeLanguageAndEnableCompanyCodeSpecificFields = (companyCode: number, paymentTermCode: string | null) => {
      const company = ALL_COMPANIES.find((c) => c.code === companyCode);
      if (company) {
        if (!isAdminUrl() && company.paymentTermAccept) {
          company.paymentTerms.forEach((paymentTerm) => {
            dispatch(addPaymentTermAccepted(paymentTerm.code));
          });
        }

        const queryStringLanguage = getQueryStringValue("language");
        const queryStringLanguageExistsInCompanyAlernativLanguages =
          company.alernativeLanguages.findIndex((a) => a === queryStringLanguage) > -1;

        if (!isNullOrUndefined(queryStringLanguage) && queryStringLanguageExistsInCompanyAlernativLanguages) {
          i18n.changeLanguage(queryStringLanguage.toString().toLowerCase());
          dispatch(setDocumentLanguageCode(queryStringLanguage.toString().toUpperCase()));
        } else {
          i18n.changeLanguage(company.language);
        }
        setBudgetEnabled(company.budget);
        if (!isAdminUrl() && company.budgetNotVisibleForCustomers) {
          setBudgetVisible(false);
        } else {
          setBudgetVisible(true);
        }
        setGuaranteeEnabled(company.guarantee);
        setGuaranteeTypeEnabled(company.guaranteeType);
        if (isNullOrUndefined(fileCard.fileCard.billToAddress.countryISOAlpha3)) {
          setDefaultCountry(company.country);
        }
        setCommentEnabled(company.comment);
        setDataProtectionCheckmark(company.dataProtectionCheckmark);
        setLogisticContactVisible(company.logisticContact);
        setNoSingleReturnVisible(company.noSingleReturn);
        setPaymentTermAccept(company.paymentTermAccept ?? false);
        if (company.additionalAddressInformation) {
          setAdditionalAddressInfoEnabled(true);
        } else {
          setAdditionalAddressInfoEnabled(false);
        }
        if (company.createAdminUser && !isAdminUrl()) {
          setCreateAdminUserVisible(company.createAdminUser);
        }

        if (!isAdminUrl() && company.guaranteeNotVisibleForCustomers) {
          setGuaranteeVisible(false);
        } else if (paymentTermCode === PAYMENT_TERM_CASH_BEFORE_DELIVERY) {
          setGuaranteeVisible(false);
        } else {
          setGuaranteeVisible(true);
        }

        if (company.hideAddShipToButton) {
          setShowAddShipToButton(false);
        } else {
          setShowAddShipToButton(true);
        }
        setHideDeliveryOverride(company.hideDeliveryOverride ? true : false);
      }
    };

    const handleCustomerStartUp = (tokenFromUrl: string) => {
      fileCardService
        .getFileCard(tokenFromUrl)
        .then((response: AxiosResponse<FileCardFromServer>): void => {
          dispatch(setFileCardId(response.data.id));

          if (response.data.billToAddress) {
            dispatch(saveFileCardFromBackend(response.data));
          }
          storeBrandsFromBackend(dispatch, response);
          storeBudgetSplitPerBrandsFromBackend(dispatch, response);
          storeCompanyCodeInFileCard(dispatch, response.data.companyCode);

          setProspectEmail(response.data.prospect.email);
          initializeFieldsFromProspectData(dispatch, response, firstShipToId);

          if (fileCard.fileCard.companyCode !== 0) {
            setCompanyCodeSpecificDefaultValues(response.data.companyCode, dispatch, firstShipToId);
          }
          changeLanguageAndEnableCompanyCodeSpecificFields(response.data.companyCode, response.data.paymentTermCode);
        })
        .catch((error: AxiosError): void => {
          const status = error.response ? error.response.status : -1;
          const errorResponseData: FileCardType = error.response?.data as FileCardType;
          const companyCode = errorResponseData.companyCode;

          console.log("handleCustomerStartUp Axios ERROR!", error);
          if (errorResponseData.companyCode) {
            changeLanguageAndEnableCompanyCodeSpecificFields(companyCode === null ? 0 : companyCode, null);
            storeCompanyCodeInFileCard(dispatch, errorResponseData.companyCode ?? 0);
          }
          if (status === 409) {
            setfileCardAlreadySaved(true);
          } else if (status === 401) {
            setTokenExpired(true);
          } //if (status === 404)
          else {
            setTokenUnknown(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const handleAdminStartup = (tokenFromUrl: string) => {
      fileCardService
        .getAdminFileCard(tokenFromUrl)
        .then((response: AxiosResponse<FileCardFromServer>): void => {
          dispatch(saveFileCardFromBackend(response.data));
          changeLanguageAndEnableCompanyCodeSpecificFields(response.data.companyCode, response.data.paymentTermCode);

          const shipToArray = response.data.shipTos;

          for (let i = 0; i < shipToArray.length; i++) {
            initializeAdminFileCard(dispatch, shipToArray[i]);
          }

          setStatus(response.data.status);
        })
        .catch((error: AxiosError): void => {
          console.log("handleAdminStartup axios ERROR!", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const tokenFromUrl = getTokenFromUrl();
    setTokenFromUrl(tokenFromUrl);
    setLoading(true);
    if (isAdminUrl()) {
      handleAdminStartup(tokenFromUrl);
    } else {
      handleCustomerStartUp(tokenFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, i18n, firstShipToId]);

  useEffect(() => {
    if (handleStartUpDone) {
      const lastObj = shipTos[shipTos.length - 1];
      initializeShipToEmails(dispatch, prospectEmail, lastObj.shipToId);
      setHandleStartUpDone(false);
    }
  }, [dispatch, handleStartUpDone, prospectEmail, shipTos]);

  const showDirectDebitEvenIfPaymentTermNotVisible = (): boolean => {
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);
    return company?.showDirectDebitEvenIfPaymentTermNotVisible ?? false;
  };

  const sendFileCard = (event: Record<string, any>): void => {
    if (form.current && !form.current.isFormValid(false)) {
      return;
    } else {
      const billingEnabledForCompany = isBillingEnabledForCompany(fileCard.fileCard.companyCode);

      const checkDirectDebit = paymentTermHasDirectDebit(
        fileCard.fileCard.companyCode,
        fileCard.fileCard.paymentTermCode
      );

      let updatedFileCard = { ...fileCard.fileCard };

      if (
        (!checkDirectDebit && (!showDirectDebitEvenIfPaymentTermNotVisible() || isAdminUrl())) ||
        !billingEnabledForCompany
      ) {
        updatedFileCard = { ...fileCard.fileCard, billing: null };
      }
      if (!logisticContactVisible) {
        updatedFileCard = { ...fileCard.fileCard, logisticContact: null };
      }
      if (isAdminUrl()) {
        sendAdminFileCard(updatedFileCard, approve, setFileCardSent, setStatus, setSaved);
      } else {
        sendCustomerFileCard(updatedFileCard, setFileCardSent, setAdminUserCreationErrorMessage, setLoading);
      }
    }
  };

  return isIE() ? (
    <InternetExplorerNotAllowed />
  ) : loading || loadingTaxJurisdictions ? (
    <LinearProgress />
  ) : fileCardSent ? (
    <div>
      {!isAdminUrl() ? (
        adminUserCreationErrorMessage !== undefined && adminUserCreationErrorMessage !== "" ? (
          <>
            <CustomerSaveConfirmation />
            {ReactHtmlParser(
              t("adminUser.errorCreatingAdminUser", {
                errorMessage: adminUserCreationErrorMessage,
              })
            )}
          </>
        ) : (
          <>
            <CustomerSaveConfirmation />
          </>
        )
      ) : status === 2 ? (
        <Route
          component={() => {
            window.location.href = process.env.REACT_APP_MDA_URL + "customer/create/fromFileCard/" + getTokenFromUrl();
            return null;
          }}
        />
      ) : undefined}
    </div>
  ) : tokenUnknown ? (
    <PageNotAvailable />
  ) : tokenExpired ? (
    <FileCardExpired companyCode={fileCard.fileCard.companyCode} />
  ) : fileCardAlreadySaved ? (
    <div>{ReactHtmlParser(t("errors.fileCardAlreadyFilledOutByCustomer"))}</div>
  ) : status === 2 ? (
    <PDFPrint fileCardId={tokenFromUrl} />
  ) : isEditUrl() && saved ? (
    <Route
      component={() => {
        window.location.href = "/admin/list";
        return null;
      }}
    />
  ) : (
    <form onSubmit={handleSubmit(sendFileCard)}>
      <div className="App">
        {isAdminUrl() ? undefined : <HeaderText companyCode={fileCard.fileCard.companyCode} />}

        <BillToAddress
          defaultCountry={defaultCountry}
          control={control}
          errors={errors}
          trigger={trigger}
          formState={formState}
          setValue={setValue}
          shipToId={firstShipToId}
          additionalAddressInfoEnabled={additionalAddressInfoEnabled}
        />
        {createAdminUserVisible && fileCard.fileCard.businessTradingPartnerId !== null ? (
          <AdminUser control={control} errors={errors} setValue={setValue} />
        ) : undefined}
        {logisticContactVisible ? (
          <LogisticContact
            control={control}
            errors={errors}
            trigger={trigger}
            formState={formState}
            setValue={setValue}
          />
        ) : undefined}

        <PaymentTerm
          control={control}
          errors={errors}
          trigger={trigger}
          setFormValue={setValue}
          formState={formState}
          setGuaranteeVisible={setGuaranteeVisible}
        />

        {paymentTermAccept && isAdminUrl() ? (
          <PaymentTermsAccept disabled={true} control={control} errors={errors} />
        ) : undefined}

        {noSingleReturnVisible ? (
          <NoSingleReturn
            companyCode={fileCard.fileCard.companyCode}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        ) : undefined}

        {budgetEnabled && budgetVisible ? <Budget control={control} errors={errors} /> : undefined}
        {(guaranteeEnabled || guaranteeTypeEnabled) && guaranteeVisible ? (
          <Guarantee
            companyCode={fileCard.fileCard.companyCode}
            control={control}
            errors={errors}
            setValue={setValue}
            guaranteeAmountEnabled={guaranteeEnabled}
            guaranteeTypeEnabled={guaranteeTypeEnabled}
          />
        ) : undefined}

        {shipTos
          ?.filter((st) => !st.deleted)
          .map((element, index) => {
            return (
              <div key={element.shipToId}>
                <DeliveryAddress
                  defaultCountry={isAdminUrl() ? "" : defaultCountry}
                  control={control}
                  errors={errors}
                  trigger={trigger}
                  formState={formState}
                  setValue={setValue}
                  shipToId={element.shipToId}
                  prospectEmail={prospectEmail}
                  additionalAddressInfoEnabled={additionalAddressInfoEnabled}
                  shipToIndex={index}
                />

                {hideDeliveryOverride ? undefined : (
                  <DeliveryOverride
                    defaultCountry={defaultCountry}
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    formState={formState}
                    setValue={setValue}
                    shipToId={element.shipToId}
                    additionalAddressInfoEnabled={additionalAddressInfoEnabled}
                  />
                )}
              </div>
            );
          })}

        {showAddShipToButton ? (
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "300px",
              marginTop: "40px",
              marginBottom: "20px",
            }}
            onClick={() => {
              dispatch(addShipTo(undefined));
              setHandleStartUpDone(true);
            }}
          >
            {t("addShipToButton")}
          </Button>
        ) : undefined}

        {commentEnabled ? <Comments control={control} errors={errors} /> : undefined}
        {fileCard.fileCard.brands ? <BrandsTable brands={fileCard.fileCard.brands} /> : undefined}
        {dataProtectionCheckmark && !isAdminUrl() ? (
          <DataProtectionCheckmarks control={control} errors={errors} />
        ) : undefined}
        {paymentTermAccept && !isAdminUrl() ? <PaymentTermsAccept control={control} errors={errors} /> : undefined}
      </div>
      <br />
      {isAdminUrl() ? (
        <AdminControls control={control} errors={errors} setApprove={setApprove} setSaved={setSaved} saved={saved} />
      ) : (
        <Button variant="contained" color="primary" type="submit" className="sendButton">
          {t("buttons.send")}
        </Button>
      )}
    </form>
  );
};

export default MainPage;
