import qs from "query-string";

export const getQueryStringValue = (key: string, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  return values[key];
};

export const stringIsNotNullOrEmpty = (input: string | string[] | null): boolean => {
  return input !== null && input !== undefined && input !== "";
};

// export const getTokenFromUrl = () => {
//   if (window.location.pathname.toLowerCase().includes("/admin/edit/")) {
//     return window.location.pathname.substring(12);
//   } else if (window.location.pathname.toLowerCase().includes("/admin/")) {
//     return window.location.pathname.substring(7);
//   }
//   return window.location.pathname.substring(1);
// };

export const getTokenFromUrl = (): string => {
  const path = window.location.pathname;
  const parts = path.split("/");
  const lastPart = parts[parts.length - 1];
  return lastPart;
};

export const isAdminUrl = (): boolean => {
  if (window.location.pathname.toLowerCase().includes("/admin/")) {
    return true;
  }
  return false;
};

export const isFileCardListUrl = (): boolean => {
  if (window.location.pathname.toLowerCase().includes("/admin/list")) {
    return true;
  }
  return false;
};

export const isEditUrl = (): boolean => {
  if (window.location.pathname.toLowerCase().includes("/admin/edit")) {
    return true;
  }
  return false;
};
