import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDCountryDropDown from "../../UI/MDCountryDropDown/MDCountryDropDown";
import MDEmailField from "../../UI/MDEmailField/MDEmailField";
import MDPhoneNumberField from "../../UI/MDPhoneNumberField/MDPhoneNumberField";
import MDPostalCodeCityField from "../../UI/MDPostalCodeCityField/MDPostalCodeCityField";
import MDTextField from "../../UI/MDTextField/MDTextField";
import MDVatNumberField from "../../UI/MDVatNumberField/MDVatNumberField";
import { isAdminUrl } from "../../utils/QueryStringUtil";
import { isNullOrUndefined } from "../../utils/CommonUtils";
import { ALL_COMPANIES, ONLY_NUMBERS_VALIDATION_REGEX } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { LegalForm } from "../../_types_/CompanySetupType";
import LegalFormFields from "./LegalFormFields";
import TaxJurisdictions from "./TaxJurisdictions";
import CompanyRegNumberNotApplicable from "./CompanyRegNumberNotApplicable";
import SalesTaxVendor from "./SalesTaxVendor";
import MDRadioButton from "../../UI/MDRadioButton/MDRadioButton";
import { TaxJurisdictionType } from "../../_types_/serverTypes/TaxJurisdictionType";
import { DropDownItem } from "../../UI/MDDropDown/MDDropDown";
import ShippingCompany from "../ShippingCompany/ShippingCompany";
import MDRegionDropDown from "../../UI/MDRegionDropDown/MDRegionDropDown";
import MDDate from "../../UI/MDDate/MDDate";
import {
  SetBillToOVT,
  setBillToAccountsDepartmentPhoneNumber,
  setBillToAccountsDepartmentPhoneNumberCallingCode,
  setBillToAdditionaAddressInfo,
  setBillToCity,
  setBillToCompanyRegistrationNumber,
  setBillToContactPersonFunction,
  setBillToCountryISOAlpha3,
  setBillToDirectPlatformEmail,
  setBillToHasVendorManual,
  setBillToKvkNumber,
  setBillToLegalAddress,
  setBillToLegalName,
  setBillToOpenSince,
  setBillToOperator,
  setBillToOrderConfirmationEmail,
  setBillToOwner,
  setBillToPostalCode,
  setBillToRegionId,
  setBillToSirenNumber,
  setBillToSmsCodeCellPhoneNumber,
  setBillToSmsCodeCellPhoneNumberCallingCode,
  setBillToStatementOfAccountEmail,
  setBillToTaxJurisdictionId,
  setBillToUseEdi,
  setBillToVatNumber,
  setBillToWholesaleAgreementEmail,
  setBillingVatNumber,
  setLogisticContactPhoneNumberCallingCode,
  setShipToCountry,
  setShipToDeliveryOverrideCountryISOAlpha3,
} from "../../_reducers_/FileCardSlice";
import { set } from "date-fns";

interface PropType {
  defaultCountry: string;
  errors: any;
  control: any;
  trigger: any;
  formState: any;
  setValue: any;
  shipToId: any;
  additionalAddressInfoEnabled: boolean;
}

const BillToAddress = (props: PropType): ReactElement<PropType> => {
  const dispatch = useDispatch();

  const [t] = useTranslation("general");

  const [kvkNumberEnabled, setKvkNumberEnabled] = useState(false);
  const [taxJurisdictionsEnabled, setTaxJurisdictionsEnabled] = useState(false);
  const [companyRegNumberNotApplicableEnabled, setCompanyRegNumberNotApplicableEnabled] = useState(false);
  const [salesTaxVendorEnabled, setSalesTaxVendorEnabled] = useState(false);
  const [ovtEnabled, setOvtEnabled] = useState(false);
  const [ovtExclusiveCountry, setOvtExclusiveCountry] = useState<string | undefined>();
  const [operatorEnabled, setOperatorEnabled] = useState(false);
  const [operatorExclusiveCountry, setOperatorExclusiveCountry] = useState<string | undefined>();
  const [useEdiEnabled, setUseEdiEnabled] = useState(false);
  const [hasVendorManualEnabled, setHasVendorManualEnabled] = useState(false);
  const [sirenNumberEnabled, setSirenNumberEnabled] = useState(false);
  const [contactPersonFunctionEnabled, setContactPersonFunctionEnabled] = useState(false);
  const [companyRegistrationNumberEnabled, setCompanyRegistrationNumberEnabled] = useState(false);
  const [regionsEnabled, setRegionsEnabled] = useState(false);
  const [directPlatformEmailEnabled, setDirectPlatformEmailEnabled] = useState(false);
  const [openSinceEnabled, setOpenSinceEnabled] = useState(false);
  const [orderConfirmationEmailEnabled, setOrderConfirmationEmailEnabled] = useState(false);
  const [hideStatementOfAccountEmail, setHideStatementOfAccountEmail] = useState(false);

  const [hideCompanyRegistrationNumberForCountry, setHideCompanyRegistrationNumberForCountry] = useState<
    string | undefined
  >(undefined);
  const [hideAccountsDepartmentPhoneNumber, setHideAccountsDepartmentPhoneNumber] = useState<boolean | undefined>(
    false
  );
  const [smsCodeCellPhoneNumberEnabled, setSmsCodeCellPhoneNumberEnabled] = useState(true);

  const [vatnumberRequired, setVatnumberRequired] = useState(true);
  const [accountsDepartmentPhoneNumberRequired, setAccountsDepartmentPhoneNumberRequired] = useState(true);

  const [legalForms, setLegalForms] = useState<LegalForm[] | null>();

  const [shippingCompanies, setShippingCompanies] = useState<DropDownItem[] | null>();

  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const { defaultCountry, errors, control, trigger, formState, setValue, shipToId } = props;

  const billToAddress = fileCard.fileCard.billToAddress;
  const companyCode = fileCard.fileCard.companyCode;

  const [vatNumberRegEx, setVatNumberRegEx] = useState<string | null>();
  const [vatNumberFormat, setVatNumberFormat] = useState<string | null>();
  const [vatNumberPrefix, setVatNumberPrefix] = useState<string | undefined>();

  const [postalCodeRegEx, setPostalCodeRegEx] = useState<string | null>();
  const [postalCodeFormat, setPostalCodeFormat] = useState<string | null>();

  const [showLegalForms, setShowLegalForms] = useState(false);

  const [legalFormExclusiveCountry, setLegalFormExclusiveCountry] = useState<string | undefined>();

  const [companyRegistrationNumberRequired, setCompanyRegistrationNumberRequired] = useState(true);

  const validateCompanyRegistrationNumber = (value: string | null) => {
    if (value === null) {
      return true;
    }

    if (defaultCountry === "GBR") {
      const re = /^[0-9]{8,9}$/;
      return re.test(String(value).toUpperCase()) ? true : t("validation.wrongFormat");
    } else if (defaultCountry === "USA") {
      const re = /^[0-9]{2}-[0-9]{7}$/;
      return re.test(String(value).toUpperCase())
        ? true
        : t("validation.wrongFormatWithExample", {
            format: "xx-xxxxxxx",
          });
    } else {
      return true;
    }
  };

  const validateOvt = (value: string | null) => {
    if (value === null || value === "" || value === undefined) {
      return true;
    }
    const re = /^[0-9]{12,17}$/;
    return re.test(String(value).toUpperCase()) ? true : t("validation.wrongFormat");
  };

  useEffect(() => {
    if (!isAdminUrl() && isNullOrUndefined(fileCard.fileCard.billToAddress.countryISOAlpha3)) {
      dispatch(setBillToCountryISOAlpha3(defaultCountry));
    }
  }, [dispatch, defaultCountry, fileCard.fileCard.billToAddress.countryISOAlpha3]);

  const allCountries = fileCard.allCountries;
  useEffect(() => {
    const setCallingCodeForSmsCodeCellPhoneNumber = (callingCode: string | undefined) => {
      dispatch(setBillToSmsCodeCellPhoneNumberCallingCode(callingCode));
    };

    const setCallingCodeForAccountsDepartmentPhoneNumber = (callingCode: string | undefined) => {
      dispatch(setBillToAccountsDepartmentPhoneNumberCallingCode(callingCode));
    };
    const setCallingCodeForLogisticContactPhoneNumber = (callingCode: string | undefined) => {
      dispatch(setLogisticContactPhoneNumberCallingCode(callingCode));
    };

    const setDeliveryAddressCountry = (isoAlpha3: string | undefined) => {
      dispatch(setShipToCountry({ shipToId: shipToId, countryISOAlpha3: isoAlpha3 }));
    };

    const setDeliveryOverrideCountry = (isoAlpha3: string | undefined) => {
      dispatch(setShipToDeliveryOverrideCountryISOAlpha3({ shipToId: shipToId, countryISOAlpha3: isoAlpha3 }));
    };

    if (allCountries !== null && billToAddress.countryISOAlpha3 !== null && billToAddress.countryISOAlpha3 !== "") {
      const country = allCountries.find((c) => c.isoAlpha3 === billToAddress.countryISOAlpha3);
      setVatNumberRegEx(country?.regExVatPattern);
      setVatNumberFormat(country?.displayVatPattern);
      setVatNumberPrefix(country?.vatCodePrefix);
      setPostalCodeRegEx(country?.postalCodePattern);
      setPostalCodeFormat(country?.displayPostalCodePattern);

      if (!isAdminUrl()) {
        setCallingCodeForAccountsDepartmentPhoneNumber(country?.callingCode);
        setCallingCodeForSmsCodeCellPhoneNumber(country?.callingCode);
        setCallingCodeForLogisticContactPhoneNumber(country?.callingCode);
        setDeliveryAddressCountry(country?.isoAlpha3);
        setDeliveryOverrideCountry(country?.isoAlpha3);
      }
    }
  }, [allCountries, billToAddress.countryISOAlpha3, dispatch, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("billToVatNumber");
    }
  }, [vatNumberRegEx, trigger, formState.isSubmitted]);

  useEffect(() => {
    if (formState.isSubmitted) {
      setValue("billToVatNumber", billToAddress.vatNumber);
      trigger("billToVatNumber");
    }
  }, [billToAddress.vatNumber, trigger, formState.isSubmitted, setValue]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("billToPostalCode");
    }
  }, [postalCodeRegEx, trigger, formState.isSubmitted]);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === companyCode);

    if (company) {
      setKvkNumberEnabled(company.kvkNumber);
      setSirenNumberEnabled(company.sirenNumber);
      setContactPersonFunctionEnabled(company.contactPersonFunction);
      setCompanyRegistrationNumberEnabled(company.companyRegistrationNumber);
      setHideCompanyRegistrationNumberForCountry(company.hideCompanyRegistrationNumberForCountry);
      setHideAccountsDepartmentPhoneNumber(company.hideAccountsDepartmentPhoneNumber);
      setLegalForms(company.legalForms);
      setLegalFormExclusiveCountry(company.legalFormExclusiveCountry);
      setVatnumberRequired(company.vatNumberRequired);
      setRegionsEnabled(company.regions ? true : false);
      setAccountsDepartmentPhoneNumberRequired(company.accountsDepartmentPhoneNumberRequired);
      setSmsCodeCellPhoneNumberEnabled(company.smsCodeCellPhoneNumber);
      setTaxJurisdictionsEnabled(company.taxJurisdictions ? company.taxJurisdictions : false);
      setCompanyRegNumberNotApplicableEnabled(
        company.companyRegistrationNumberNotApplicable ? company.companyRegistrationNumberNotApplicable : false
      );
      setSalesTaxVendorEnabled(company.salesTaxVendor ? company.salesTaxVendor : false);
      setUseEdiEnabled(company.useEDI ? company.useEDI : false);
      setHasVendorManualEnabled(company.hasVendorManual ? company.hasVendorManual : false);
      setShippingCompanies(company.shippingCompanies);
      setOvtExclusiveCountry(company.ovtExclusiveCountry);
      setOperatorExclusiveCountry(company.operatorExclusiveCountry);
      setDirectPlatformEmailEnabled(company.directPlatformEmail ? true : false);
      setOpenSinceEnabled(company.openSince ? true : false);
      setOrderConfirmationEmailEnabled(company.orderConfirmationEmailOnBillTo ? true : false);
      setHideStatementOfAccountEmail(company.hideStatementOfAccountEmail ? company.hideStatementOfAccountEmail : false);
    }
  }, [companyCode]);

  useEffect(() => {
    if (hideCompanyRegistrationNumberForCountry !== undefined) {
      if (hideCompanyRegistrationNumberForCountry === fileCard.fileCard.billToAddress.countryISOAlpha3) {
        setCompanyRegistrationNumberEnabled(false);
      } else {
        setCompanyRegistrationNumberEnabled(true);
      }
    }
  }, [
    hideCompanyRegistrationNumberForCountry,
    companyRegistrationNumberEnabled,
    fileCard.fileCard.billToAddress?.countryISOAlpha3,
  ]);

  useEffect(() => {
    if (ovtExclusiveCountry !== undefined && ovtExclusiveCountry === fileCard.fileCard.billToAddress.countryISOAlpha3) {
      setOvtEnabled(true);
    } else {
      setOvtEnabled(false);
    }
  }, [ovtExclusiveCountry, fileCard.fileCard.billToAddress.countryISOAlpha3]);

  useEffect(() => {
    if (
      operatorExclusiveCountry !== undefined &&
      operatorExclusiveCountry === fileCard.fileCard.billToAddress.countryISOAlpha3
    ) {
      setOperatorEnabled(true);
    } else {
      setOperatorEnabled(false);
    }
  }, [operatorExclusiveCountry, fileCard.fileCard.billToAddress.countryISOAlpha3]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("billToVatNumber");
    }
  }, [vatNumberRegEx, trigger, formState.isSubmitted]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("billToPostalCode");
    }
  }, [postalCodeRegEx, trigger, formState.isSubmitted]);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === companyCode);

    if (company && billToAddress.countryISOAlpha3) {
      if (billToAddress.countryISOAlpha3 === company.companyRegistrationNumberOptionalCountry) {
        setCompanyRegistrationNumberRequired(false);
      } else {
        setCompanyRegistrationNumberRequired(true);
      }
    }
  }, [companyCode, billToAddress.countryISOAlpha3]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("companyRegistrationNumber");
    }
  }, [companyRegistrationNumberRequired, formState.isSubmitted, trigger]);

  useEffect(() => {
    if (legalForms != null) {
      setShowLegalForms(true);
      if (legalFormExclusiveCountry !== undefined) {
        if (legalFormExclusiveCountry === fileCard.fileCard.billToAddress.countryISOAlpha3) {
          setShowLegalForms(true);
        } else {
          setShowLegalForms(false);
        }
      }
    } else {
      setShowLegalForms(false);
    }
  }, [showLegalForms, legalForms, legalFormExclusiveCountry, fileCard.fileCard.billToAddress.countryISOAlpha3]);

  return (
    <div>
      <h2>{t("billToAddress.headline")}</h2>
      <MDTextField
        control={control}
        setValue={setValue}
        label={t("billToAddress.billToLegalName")}
        name="billToLegalName"
        error={errors.billToLegalName}
        value={billToAddress.legalName ? billToAddress.legalName : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setBillToLegalName(e));
        }}
        rules={{
          required: { message: t("validation.requiredField"), value: true },
        }}
      />
      <MDTextField
        control={control}
        setValue={setValue}
        label={t("billToAddress.billToLegalAddress")}
        name="billToLegalAddress"
        error={errors.billToLegalAddress}
        value={billToAddress.legalAddress ?? ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setBillToLegalAddress(e));
        }}
        rules={{
          required: { message: t("validation.requiredField"), value: true },
        }}
      />
      {props.additionalAddressInfoEnabled ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.billToAdditionalAddressInfo")}
          name="billToAdditionalAddressInfo"
          error={errors.billToAdditionalAddressInfo}
          value={billToAddress.additionalAddressInfo ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillToAdditionaAddressInfo(e));
          }}
          rules={{
            maxLength: {
              message: t("validation.maxNumberDigits", { number: 100 }),
              value: 100,
            },
          }}
        />
      ) : undefined}

      <MDPostalCodeCityField
        control={control}
        setValue={setValue}
        postalCodeLabel={t("shared.postal-code")}
        postalCodeName="billToPostalCode"
        postalCodeError={errors.billToPostalCode}
        postalCodeValue={billToAddress.postalCode ?? ""}
        postalCodeOnChange={(e: string): void => {
          dispatch(setBillToPostalCode(e));
        }}
        postalCodeRegexPattern={postalCodeRegEx}
        postalCodeDisplayFormat={postalCodeFormat}
        cityLabel={t("shared.city")}
        cityName="billToCity"
        cityError={errors.billToCity}
        cityValue={billToAddress.city ?? ""}
        cityOnChange={(e: string): void => {
          dispatch(setBillToCity(e));
        }}
      />

      <MDCountryDropDown
        control={control}
        setValue={setValue}
        trigger={trigger}
        formState={formState}
        defaultCountry={defaultCountry}
        countryLabel={t("shared.country")}
        countryName="billToAddressCountryISOAlpha3"
        countryError={errors.billToAddressCountryISOAlpha3}
        countryValue={billToAddress ? (billToAddress.countryISOAlpha3 ? billToAddress.countryISOAlpha3 : "") : ""}
        countryOnChange={(event: string): void => {
          dispatch(setBillToCountryISOAlpha3(event));
        }}
      />

      {regionsEnabled ? (
        <MDRegionDropDown
          control={control}
          setValue={setValue}
          trigger={trigger}
          formState={formState}
          countryValue={billToAddress ? (billToAddress.countryISOAlpha3 ? billToAddress.countryISOAlpha3 : "") : ""}
          error={errors.billToRegion}
          regionName="billToRegion"
          label={t("shared.region")}
          value={billToAddress.regionId}
          onChange={(event: number): void => {
            dispatch(setBillToRegionId(event));
          }}
        />
      ) : undefined}

      {taxJurisdictionsEnabled ? (
        <TaxJurisdictions
          error={props.errors.billToAddressTaxJurisdiction}
          control={control}
          setValue={setValue}
          name="billToAddressTaxJurisdiction"
          value={fileCard.fileCard.billToAddress.taxJurisdictionId ?? 0}
          onChangeSetValue={(e: TaxJurisdictionType): void => {
            dispatch(setBillToTaxJurisdictionId(e ? e.id : null));
          }}
        />
      ) : undefined}

      {vatNumberRegEx ? (
        <MDVatNumberField
          control={control}
          setValue={setValue}
          trigger={trigger}
          formState={formState}
          vatNumberLabel={t("billToAddress.billToVatNumber")}
          vatNumberName="billToVatNumber"
          vatNumberError={errors.billToVatNumber}
          vatNumberValue={billToAddress.vatNumber ?? ""}
          vatNumberRequired={vatnumberRequired}
          vatNumberRegexPattern={vatNumberRegEx}
          vatNumberPrefix={vatNumberPrefix}
          vatNumberFormat={vatNumberFormat}
          vatNumberOnChange={(e: string): void => {
            let tempVatNumber: string | null = e;
            if (tempVatNumber === "") {
              tempVatNumber = null;
            }
            dispatch(setBillToVatNumber(tempVatNumber));
            dispatch(setBillingVatNumber(tempVatNumber));
          }}
        />
      ) : undefined}

      {companyRegNumberNotApplicableEnabled ? (
        <CompanyRegNumberNotApplicable
          control={control}
          errors={errors}
          setCompanyRegistrationNumberEnabled={setCompanyRegistrationNumberEnabled}
        />
      ) : undefined}

      {companyRegistrationNumberEnabled ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.companyRegistrationNumber")}
          name="companyRegistrationNumber"
          error={errors.companyRegistrationNumber}
          value={billToAddress?.companyRegistrationNumber ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillToCompanyRegistrationNumber(e));
          }}
          rules={{
            required: {
              message: t("validation.requiredField"),
              value: companyRegistrationNumberRequired,
            },
            validate: validateCompanyRegistrationNumber,
          }}
        />
      ) : undefined}

      {salesTaxVendorEnabled ? <SalesTaxVendor errors={errors} control={control} setValue={setValue} /> : undefined}

      {openSinceEnabled ? (
        <MDDate
          control={control}
          disabled={false}
          dateLabel={t("billToAddress.openSince")}
          dateName="openSince"
          dateError={errors.openSince}
          dateFormat="yyyy-MM-dd"
          dateValue={
            isNullOrUndefined(fileCard.fileCard.billToAddress.openSince)
              ? null
              : fileCard.fileCard.billToAddress.openSince
          }
          dateOnChange={(e: string): void => {
            dispatch(setBillToOpenSince(e));
          }}
          rules={{
            required: {
              message: t("validation.requiredField"),
              value: true,
            },
          }}
        />
      ) : undefined}

      {showLegalForms && legalForms != null ? (
        <LegalFormFields legalForms={legalForms} control={control} errors={errors} />
      ) : undefined}

      {kvkNumberEnabled ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.billToKvkNumber")}
          name="billToKvkNumber"
          error={errors.billToKvkNumber}
          value={billToAddress.kvkNumber ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillToKvkNumber(e));
          }}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
            min: {
              message: t("validation.cannotBeANegativeNumber"),
              value: 0,
            },
            maxLength: {
              message: t("validation.maxNumberDigits", { number: 8 }),
              value: 8,
            },
            minLength: {
              message: t("validation.minNumberDigits", { number: 8 }),
              value: 8,
            },
            pattern: {
              value: ONLY_NUMBERS_VALIDATION_REGEX,
              message: t("validation.canOnlyHoldNumbers"),
            },
          }}
        />
      ) : undefined}
      {sirenNumberEnabled ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.billToSirenNumber")}
          name="billToSirenNumber"
          error={errors.billToSirenNumber}
          value={billToAddress.sirenNumber ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillToSirenNumber(e));
          }}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
            min: {
              message: t("validation.cannotBeANegativeNumber"),
              value: 0,
            },
            maxLength: {
              message: t("validation.maxNumberDigits", { number: 9 }),
              value: 9,
            },
            minLength: {
              message: t("validation.minNumberDigits", { number: 9 }),
              value: 9,
            },
            pattern: {
              value: ONLY_NUMBERS_VALIDATION_REGEX,
              message: t("validation.canOnlyHoldNumbers"),
            },
          }}
        />
      ) : undefined}

      <MDTextField
        control={control}
        setValue={setValue}
        label={t("billToAddress.billToOwner")}
        name="billToOwner"
        error={errors.billToOwner}
        value={billToAddress.owner ?? ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setBillToOwner(e));
        }}
        rules={{
          required: { message: t("validation.requiredField"), value: true },
        }}
      />
      {contactPersonFunctionEnabled ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.contactPersonFunction")}
          name="billToContactPersonFunction"
          error={errors.billToContactPersonFunction}
          value={billToAddress.contactPersonFunction ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillToContactPersonFunction(e));
          }}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
          }}
        />
      ) : undefined}

      {ovtEnabled ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.ovt")}
          name="billToOvt"
          error={errors.billToOvt}
          value={billToAddress.ovt ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(SetBillToOVT(e));
          }}
          rules={{
            required: { message: t("validation.requiredField"), value: false },
            validate: validateOvt,
          }}
        />
      ) : undefined}

      {operatorEnabled ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.operator")}
          name="billToOperator"
          error={errors.billToOperator}
          value={billToAddress.operator ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillToOperator(e));
          }}
          rules={{
            maxLength: {
              message: t("validation.maxNumberDigits", { number: 100 }),
              value: 100,
            },
          }}
        />
      ) : undefined}

      {hideAccountsDepartmentPhoneNumber ? undefined : (
        <MDPhoneNumberField
          control={control}
          setValue={setValue}
          trigger={trigger}
          formState={formState}
          phoneNumberRequired={accountsDepartmentPhoneNumberRequired}
          callingCodeName="billToAccountsDepartmentPhoneNumberCallingCode"
          callingCodeError={errors.billToAccountsDepartmentPhoneNumberCallingCode}
          callingCodeValue={billToAddress.accountsDepartmentPhoneNumber?.callingCode ?? ""}
          callingCodeOnChange={(e: string): void => {
            dispatch(setBillToAccountsDepartmentPhoneNumberCallingCode(e));
          }}
          phoneNumberName="billToAccountsDepartmentPhoneNumber"
          phoneNumberError={errors.billToAccountsDepartmentPhoneNumber}
          phoneNumberLabel={t("billToAddress.billToAccountsDepartmentPhoneNumber")}
          phoneNumberValue={billToAddress.accountsDepartmentPhoneNumber?.phoneNumber ?? ""}
          phoneNumberOnChange={(e: string): void => {
            dispatch(setBillToAccountsDepartmentPhoneNumber(e));
          }}
        />
      )}

      {hideStatementOfAccountEmail ? undefined : (
        <MDEmailField
          control={control}
          setValue={setValue}
          emailLabel={t("billToAddress.billToStatementOfAccountEmail")}
          emailName="billToStatementOfAccountEmail"
          emailError={errors.billToStatementOfAccountEmail}
          emailValue={billToAddress ? billToAddress.statementOfAccountEmail : ""}
          emailOnChange={(e: string): void => {
            dispatch(setBillToStatementOfAccountEmail(e));
          }}
        />
      )}

      <MDEmailField
        control={control}
        setValue={setValue}
        emailLabel={t("billToAddress.billToWholesaleAgreementEmail")}
        emailName="billToWholesaleAgreementEmail"
        emailError={errors.billToWholesaleAgreementEmail}
        emailValue={billToAddress ? billToAddress.wholesaleAgreementEmail : ""}
        emailOnChange={(e: string): void => {
          dispatch(setBillToWholesaleAgreementEmail(e));
        }}
      />

      {directPlatformEmailEnabled ? (
        <MDEmailField
          control={control}
          setValue={setValue}
          emailLabel={t("billToAddress.billToDirectPlatformEmail")}
          emailName="billToDirectPlatformEmail"
          emailError={errors.billToDirectPlatformEmail}
          emailValue={billToAddress ? billToAddress.directPlatformEmail : ""}
          emailOnChange={(e: string): void => {
            dispatch(setBillToDirectPlatformEmail(e));
          }}
        />
      ) : undefined}

      {orderConfirmationEmailEnabled ? (
        <MDEmailField
          control={control}
          setValue={setValue}
          emailLabel={t("billToAddress.orderConfirmationEmail")}
          emailName="billToOrderConfirmationEmail"
          emailError={errors.billToOrderConfirmationEmail}
          emailValue={billToAddress ? billToAddress.orderConfirmationEmail : ""}
          emailOnChange={(e: string): void => {
            dispatch(setBillToOrderConfirmationEmail(e));
          }}
        />
      ) : undefined}

      {smsCodeCellPhoneNumberEnabled ? (
        <MDPhoneNumberField
          control={control}
          setValue={setValue}
          trigger={trigger}
          formState={formState}
          phoneNumberRequired={true}
          callingCodeName="billToSmsCodeCellPhoneNumberCallingCode"
          callingCodeError={errors.billToSmsCodeCellPhoneNumberCallingCode}
          callingCodeValue={billToAddress?.smsCodeCellPhoneNumber?.callingCode ?? ""}
          callingCodeOnChange={(e: string): void => {
            dispatch(setBillToSmsCodeCellPhoneNumberCallingCode(e));
          }}
          phoneNumberLabel={t("billToAddress.billToSmsCodeCellPhoneNumber")}
          phoneNumberName="billToSmsCodeCellPhoneNumber"
          phoneNumberError={errors.billToSmsCodeCellPhoneNumber}
          phoneNumberValue={billToAddress?.smsCodeCellPhoneNumber?.phoneNumber ?? ""}
          phoneNumberOnChange={(e: string): void => {
            dispatch(setBillToSmsCodeCellPhoneNumber(e));
          }}
        />
      ) : undefined}

      {useEdiEnabled ? (
        <MDRadioButton
          control={props.control}
          error={props.errors.useEDIQuestion}
          name="useEDIQuestion"
          label={t("billToAddress.useEDIQuestion")}
          onChangeSetValue={(event: ChangeEvent<HTMLInputElement>): void => {
            dispatch(setBillToUseEdi((event.target as HTMLInputElement).value === "true"));
          }}
          valueToMatchCheckedWith={
            fileCard.fileCard.billToAddress.useEDI === undefined ? null : fileCard.fileCard.billToAddress.useEDI
          }
          opt1Label={t("shared.yes")}
          opt2Label={t("shared.no")}
          setValue={props.setValue}
          opt1Value={true}
          opt2Value={false}
          validationText={t("validation.selectionRequired")}
        />
      ) : undefined}
      {hasVendorManualEnabled ? (
        <MDRadioButton
          control={props.control}
          error={props.errors.vendorManualQuestion}
          name="vendorManualQuestion"
          label={t("billToAddress.vendorManualQuestion")}
          onChangeSetValue={(event: ChangeEvent<HTMLInputElement>): void => {
            dispatch(setBillToHasVendorManual((event.target as HTMLInputElement).value === "true"));
          }}
          valueToMatchCheckedWith={
            fileCard.fileCard.billToAddress.hasVendorManual === undefined
              ? null
              : fileCard.fileCard.billToAddress.hasVendorManual
          }
          opt1Label={t("shared.yes")}
          opt2Label={t("shared.no")}
          setValue={props.setValue}
          opt1Value={true}
          opt2Value={false}
          validationText={t("validation.selectionRequired")}
        />
      ) : undefined}
      {shippingCompanies ? (
        <ShippingCompany
          control={props.control}
          errors={props.errors}
          shippingCompanies={shippingCompanies}
          setValue={props.setValue}
        />
      ) : undefined}
    </div>
  );
};

export default BillToAddress;
