export const isEmpty = (value: string | null) => {
  return value == null || value.length === 0;
};

export const isNullOrUndefined = (value: any | null | undefined): value is null | undefined => {
  return value === null || typeof value === "undefined" || value.length === 0 || value === 0 || value === "";
};

export const isNegativeNumber = (num: number) => {
  if (Math.sign(num) === -1) {
    return true;
  }

  return false;
};
