import React, { ReactElement } from "react";
import postalCodeCityClasses from "./MDPostalCodeCityField.module.scss";
import { useTranslation } from "react-i18next";
import MDTextField from "../MDTextField/MDTextField";
import { ProspectType } from "../../_types_/ProspectType";

interface PropType {
  control: any;
  setValue: any;
  postalCodeLabel: string;
  postalCodeName: string;
  postalCodeError: any;
  postalCodeValue: any;
  postalCodeOnChange: (value: any) => void;
  postalCodeRegexPattern: any;
  postalCodeDisplayFormat: string | null | undefined;
  cityLabel: string;
  cityName: string;
  cityError: any;
  cityValue: any;
  cityOnChange: (value: any) => void;
}

const MDPostalCodeCityField = (props: PropType): ReactElement<ProspectType> => {
  const [t] = useTranslation("general");

  const validatePostalCode = (value: string | null) => {
    if (value === null || props.postalCodeRegexPattern === null) {
      return true;
    } else {
      const re = new RegExp(props.postalCodeRegexPattern ?? "");
      return re.test(String(value).toUpperCase())
        ? true
        : t("validation.wrongPostalCodeFormat", {
            format: props.postalCodeDisplayFormat,
          });
    }
  };

  return (
    <div className={postalCodeCityClasses.flexContainer}>
      <MDTextField
        className={postalCodeCityClasses.postalCode}
        control={props.control}
        setValue={props.setValue}
        label={props.postalCodeLabel}
        name={props.postalCodeName}
        error={props.postalCodeError}
        value={props.postalCodeValue}
        onChangeSetValue={props.postalCodeOnChange}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: true,
          },
          validate: validatePostalCode,
        }}
      />
      <MDTextField
        className={postalCodeCityClasses.city}
        control={props.control}
        setValue={props.setValue}
        label={props.cityLabel}
        name={props.cityName}
        error={props.cityError}
        value={props.cityValue}
        flex="auto"
        onChangeSetValue={props.cityOnChange}
        rules={{
          required: { message: t("validation.requiredField"), value: true },
        }}
      />
    </div>
  );
};

export default MDPostalCodeCityField;
